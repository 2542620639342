import { SuccessResponse } from "common/common.interface";
import { useAppDispatch, useAppSelector, useSnack } from "common/hooks";
import { useMutationWithLoading } from "common/hooks/src/api/useMutationWithLoading";
import { useQueryWithLoading } from "common/hooks/src/api/useQueryWithLoading";
import {
  RateExercisePayload,
  Session,
  SessionChart,
} from "modules/session/types/session.interface";
import { UseMutationResult, UseQueryResult } from "react-query";
import {
  resetSession,
  setAddExerciseCompleted,
  setSession,
} from "../store/slice";
import { getSessionBackend } from "./backends";
import { SESSION_ROUTES } from "./session.api.enum";

export const useStartSession = (
  cabinetId: string
): UseMutationResult<Session, Error, string> => {
  const setSnack = useSnack();
  const dispatch = useAppDispatch();

  const startSession = async (code: string): Promise<Session> => {
    const authBackend = await getSessionBackend();
    return await authBackend.startSession(cabinetId, code);
  };

  return useMutationWithLoading((code: string) => startSession(code), {
    onSuccess(data: Session) {
      dispatch(setSession({ session: data }));
    },
    onError() {
      setSnack({
        title: "Code incorrect!",
        severityType: "error",
      });
    },
  });
};

export const useRateExerciseDifficulty = (
  exerciseId: string
): UseMutationResult<SuccessResponse, Error, RateExercisePayload> => {
  const setSnack = useSnack();
  const dispatch = useAppDispatch();
  const sessionId = useAppSelector(
    (state) => state.session.session?.session._id
  );
  const numberOfExercises = useAppSelector(
    (state) => state.session.session?.exercises
  ).length;
  const numberOfExercisesCompleted = useAppSelector(
    (state) => state.session.exerciseCompleted
  ).length;

  const { mutate } = useEndSession();

  const rateExercise = async (
    payload: RateExercisePayload
  ): Promise<SuccessResponse> => {
    const sessionBackend = await getSessionBackend();
    return await sessionBackend.rateExercise(exerciseId, sessionId, payload);
  };

  return useMutationWithLoading(
    (payload: RateExercisePayload) => rateExercise(payload),
    {
      onSuccess() {
        if (numberOfExercises === numberOfExercisesCompleted + 1) {
          mutate();
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage("sessionEnded");
          } else {
            console.log("ReactNativeWebView is not defined");
          }
        }
        dispatch(setAddExerciseCompleted(exerciseId));
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useEndSession = (): UseMutationResult<
  SuccessResponse,
  Error,
  void
> => {
  const setSnack = useSnack();
  const sessionId = useAppSelector(
    (state) => state.session.session?.session._id
  );
  const dispatch = useAppDispatch();

  const endSession = async (): Promise<SuccessResponse> => {
    const sessionBackend = await getSessionBackend();
    return await sessionBackend.endSession(sessionId);
  };

  return useMutationWithLoading(() => endSession(), {
    onSuccess() {
      dispatch(resetSession());
    },
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
  });
};

export const useGetUserSessionChart = (
  userId: string
): UseQueryResult<SessionChart, Error> => {
  const setSnack = useSnack();

  const getUserSessionChart = async (): Promise<SessionChart> => {
    const sessionBackend = await getSessionBackend();
    return await sessionBackend.getUserSessionChart(userId);
  };

  return useQueryWithLoading(
    SESSION_ROUTES.GET_USER_SESSION_CHART,
    getUserSessionChart,
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useGetSession = (
  sessionId: string
): UseQueryResult<Session, Error> => {
  const setSnack = useSnack();
  const dispatch = useAppDispatch();

  const getSession = async (): Promise<Session> => {
    dispatch(resetSession());
    const sessionBackend = await getSessionBackend();
    return await sessionBackend.getSession(sessionId);
  };

  return useQueryWithLoading(SESSION_ROUTES.GET_SESSION, getSession, {
    onSuccess(data: Session) {
      dispatch(setSession({ session: data }));
    },
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
  });
};
