import { SuccessResponse } from "common/common.interface";
import { environment } from "common/environment";
import {
  AddStaffPayload,
  CabinetStaff,
  PaymentHistory
} from "modules/cabinets/types/cabinets.interface";

export interface ICabinetBackend {
  getCabinetStaff(): Promise<CabinetStaff[]>;
  getCabinetSubHistory(): Promise<PaymentHistory>;
  addToStaff(payload: AddStaffPayload): Promise<CabinetStaff>;
  removeFromStaff(userId: string): Promise<SuccessResponse>;
  getSubscriptionStatus(): Promise<SuccessResponse<{ active: true }>>;
  getSubscriptionLink(): Promise<SuccessResponse<{ link: string }>>;
}

let cabinetBackendInstance: ICabinetBackend | undefined;

export async function getCabinetBackend(): Promise<ICabinetBackend> {
  if (cabinetBackendInstance === undefined) {
    const mod = await import(`./${environment.CABINET_BACKEND}`);
    cabinetBackendInstance = new mod.default() as ICabinetBackend;
  }
  return cabinetBackendInstance;
}
