import Card from "components/card";
import React from "react";
import { MdArrowLeft, MdArrowRight, MdEdit } from "react-icons/md";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { FiSearch } from "react-icons/fi";

export type RowObjPatient = {
  id: string;
  name: string;
  birthDay: string;
  unicode: string | null;
};

const columnHelper = createColumnHelper<RowObjPatient>();

// const columns = columnsDataCheck;
export default function PatientTable(props: {
  tableData: any;
  searchName: string;
  setSearchName: any;
  currentPage: number;
  totalPage: number;
  hasNextPage: boolean;
  handleFetchNextPage: () => void;
  handleFetchPrevPage: () => void;
}) {
  const {
    tableData,
    handleFetchNextPage,
    hasNextPage,
    handleFetchPrevPage,
    totalPage,
    currentPage,
  } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("name", {
      id: "Nom",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Nom</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()} <span className="text-xs text-gray-400"></span>
        </p>
      ),
    }),
    columnHelper.accessor("birthDay", {
      id: "Anniversaire",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Date de naissance
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {new Date(info.getValue()).toLocaleDateString()}
        </p>
      ),
    }),
    columnHelper.accessor("unicode", {
      id: "Code",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Code</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue() ?? "-"}
        </p>
      ),
    }),
    columnHelper.accessor("id", {
      id: "actions",
      header: () => (
        <p className="flex justify-end text-sm font-bold text-gray-600 dark:text-white">
          MODIFIER
        </p>
      ),
      cell: (id) => (
        <div className="flex items-end justify-end">
          <a
            className="text-xl font-bold text-brand-500 dark:text-white"
            href={`/admin/patients/${id.getValue()}`}
          >
            <MdEdit />
          </a>
        </div>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Patients
        </div>
        <div className="flex items-center gap-4">
          <div className="flex h-full items-center rounded-full bg-lightPrimary py-3.5 text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[250px]">
            <p className="pl-3 pr-2 text-xl">
              <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
            </p>
            <input
              type="text"
              placeholder="Search..."
              className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
              value={props.searchName}
              onChange={(e) => props.setSearchName(e.target.value)}
            />
          </div>
          <a
            className="cursor-pointer rounded-full bg-brand-500 p-0.5 text-white"
            href="/admin/patients/create"
          >
            <svg
              className="h-8 w-8 cursor-pointer"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
          </a>
        </div>
      </div>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows.slice(0)
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="mt-5 flex items-center justify-end">
        <MdArrowLeft
          onClick={handleFetchPrevPage}
          className={`h-8 w-8 cursor-pointer ${
            currentPage - 1 > 0 ? "text-brand-500" : "text-gray-400"
          }`}
        />
        <p className="text-sm text-navy-700 dark:text-white">
          Page {currentPage} sur {totalPage}
        </p>
        <MdArrowRight
          onClick={handleFetchNextPage}
          className={`h-8 w-8 cursor-pointer ${
            hasNextPage ? "text-brand-500" : "text-gray-400"
          }`}
        />
      </div>
    </Card>
  );
}
