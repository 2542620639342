export interface Patient {
  _id: string;
  firstName: string;
  lastName: string;
  unicode: string | null;
  createdAt: Date;
  archived: Date;
  birthDay: string;
  gender: GENDER;
  cabinetId: string;
  practitionerId: string;
  activeProtocolIds: string[];
}

export enum GENDER {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export interface CreatePatientPayload {
  firstName: string;
  lastName: string;
  cabinetId: string;
  activeProtocolIds: string[];
  birthDay: Date;
  gender: GENDER;
  practitionerId: string;
}
