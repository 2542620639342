import { Navigate, Route, Routes } from "react-router-dom";

import { LayoutController } from "common/hooks";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";

const App = () => {
  return (
    <LayoutController>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="/" element={<Navigate to="/admin" replace />} />
      </Routes>
    </LayoutController>
  );
};

export default App;
