import { SuccessResponse } from "common/common.interface";
import { environment } from "common/environment";
import { Pagination } from "modules/exercises/types/exercises.interface";
import { CreatePatientPayload, Patient } from "modules/patients/types/patient.interface";

export interface PatientsBackend {
  getPatients(
    page: number,
    limit: number,
    searchName: string | undefined
  ): Promise<Pagination<Patient>>;
  getPatient(id: string): Promise<Patient>;
  createPatient(
    cabinetId: string,
    payload: CreatePatientPayload
  ): Promise<Patient>;
  updatePatient(id: string, payload: CreatePatientPayload): Promise<Patient>;
  archivePatient(id: string): Promise<SuccessResponse>;
}

let patientsBackendInstance: PatientsBackend | undefined;

export async function getPatientsBackend(): Promise<PatientsBackend> {
  if (patientsBackendInstance === undefined) {
    const mod = await import(`./${environment.PATIENT_BACKEND}`);
    patientsBackendInstance = new mod.default() as PatientsBackend;
  }
  return patientsBackendInstance;
}
