import { Spinner } from "components/Spinner";
import { useEffect, useState } from "react";

export default function CancelSubscription() {
  const [decount, setDecount] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setDecount((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (decount === 0) {
      window.location.replace("/");
    }
  }, [decount]);

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 text-center">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Paiement échoué
        </h4>
        <Spinner />
        <p className="mb-6 ml-1 text-base text-gray-600 mt-2">
          Redirection dans {decount} secondes
        </p>
      </div>
    </div>
  );
}
