import { SuccessResponse } from "common/common.interface";
import { useAppDispatch, useSnack } from "common/hooks";
import { useMutationWithLoading } from "common/hooks/src/api/useMutationWithLoading";
import { setUser } from "modules/auth/store";
import {
  AuthenticatedUser,
  CabinetUserRole,
  CabinetUserState,
  ISignInWithUserPasswordPayload,
  SetPasswordPayload,
} from "modules/auth/types/auth.interface";
import { UseMutationResult } from "react-query";
import { useNavigate } from "react-router-dom";
import { getAuthBackend } from "./backends";

export const useSignIn = (): UseMutationResult<
  AuthenticatedUser,
  Error,
  ISignInWithUserPasswordPayload
> => {
  const setSnack = useSnack();
  const dispatch = useAppDispatch();

  const signInUser = async (
    credentials: ISignInWithUserPasswordPayload
  ): Promise<AuthenticatedUser> => {
    const authBackend = await getAuthBackend();
    return await authBackend.signInWithEmailPassword(credentials);
  };

  return useMutationWithLoading(
    (credentials: ISignInWithUserPasswordPayload) => signInUser(credentials),
    {
      onSuccess(data: AuthenticatedUser) {
        if (data.role === CabinetUserRole.SuperAdmin) {
          setSnack({
            title: "Vous n'êtes pas autorisé à accéder à cette page!",
            severityType: "error",
          });
          return;
        }

        dispatch(setUser({ user: data }));
      },
      onError() {
        setSnack({
          title: "Mots de passe ou email incorrect!",
          severityType: "error",
        });
      },
    }
  );
};

export const useGetUserState = (): UseMutationResult<
  SuccessResponse<CabinetUserState>,
  Error,
  string
> => {
  const setSnack = useSnack();

  const getUserState = async (
    email: string
  ): Promise<SuccessResponse<CabinetUserState>> => {
    const authBackend = await getAuthBackend();
    return await authBackend.getUserState(email);
  };

  return useMutationWithLoading((email: string) => getUserState(email), {
    onError() {
      setSnack({
        title: "Erreur inconnue! Veuillez réessayer plus tard!",
        severityType: "error",
      });
    },
  });
};

export const useSendResetPassword = (): UseMutationResult<
  SuccessResponse,
  Error,
  string
> => {
  const setSnack = useSnack();

  const sendResetPassword = async (email: string): Promise<SuccessResponse> => {
    const authBackend = await getAuthBackend();
    return await authBackend.sendResetPassword(email);
  };

  return useMutationWithLoading((email: string) => sendResetPassword(email), {
    onSuccess() {
      setSnack({
        title: "Email envoyé avec succès!",
        severityType: "success",
      });
    },
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
  });
};

export const useVerifyIfCodeIsValid = (): UseMutationResult<
  SuccessResponse,
  Error,
  string
> => {
  const setSnack = useSnack();

  const verifyIfCodeIsValid = async (
    code: string
  ): Promise<SuccessResponse> => {
    const authBackend = await getAuthBackend();
    return await authBackend.verifyCode(code);
  };

  return useMutationWithLoading((code: string) => verifyIfCodeIsValid(code), {
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
  });
};

export const useSetPassword = (): UseMutationResult<
  SuccessResponse,
  Error,
  SetPasswordPayload
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();

  const setPassword = async (
    payload: SetPasswordPayload
  ): Promise<SuccessResponse> => {
    const authBackend = await getAuthBackend();
    return await authBackend.setPassword(payload);
  };

  return useMutationWithLoading(
    (payload: SetPasswordPayload) => setPassword(payload),
    {
      onSuccess() {
        setSnack({
          title: "Mot de passe mis à jour avec succès!",
          severityType: "success",
        });
        navigate("/auth/sign-in");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useResendSetPassword = (): UseMutationResult<
  SuccessResponse,
  Error,
  string
> => {
  const setSnack = useSnack();

  const resendSetPassword = async (email: string): Promise<SuccessResponse> => {
    const authBackend = await getAuthBackend();
    return await authBackend.resendSetPassword(email);
  };

  return useMutationWithLoading(
    (email: string) => resendSetPassword(email),
    {
      onSuccess() {
        setSnack({
          title: "Email envoyé avec succès!",
          severityType: "success",
        });
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};
