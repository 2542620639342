import Card from "components/card";
import BarChart from "components/charts/BarChart";
import { useGetUserSessionChart } from "modules/session/api";
import { useCallback } from "react";
import { barChartOptionsDailyTraffic } from "variables/charts";

interface DailyTrafficProps {
  id: string;
}

const DailyTraffic = ({ id }: DailyTrafficProps) => {
  const { data } = useGetUserSessionChart(id);

  const handleGetAveragePain = useCallback(() => {
    const sessions = data?.sessions;
    if (!sessions) return [];

    return Object.entries(data?.sessions).map(
      ([key, value]) => value[0].averagePain
    );
  }, [data]);

  const handleGetAverageDifficulty = useCallback(() => {
    const sessions = data?.sessions;
    if (!sessions) return [];

    return Object.entries(data?.sessions).map(
      ([key, value]) => value[0].averageDifficulty
    );
  }, [data]);

  const formatKey = (key: string) => {
    // 2024-M4
    const [year, month] = key.split("-");
    const monthName = new Date(
      parseInt(year),
      parseInt(
        typeof month === "string"
          ? month.startsWith("M")
            ? month.replace("M", "")
            : (parseInt(month) - 1).toString()
          : (month - 1).toString()
      ) - 1,
      1
    ).toLocaleString("default", { month: "short" });

    return `${monthName} ${year}`;
  };

  if (!data) return null;

  return (
    <Card extra="pb-7 p-[20px]">
      <div className="flex flex-row justify-between">
        <div className="ml-1 pt-2">
          <p className="text-sm font-medium leading-4 text-gray-600">
            Séance effectuée
          </p>
          <p className="text-[34px] font-bold text-navy-700 dark:text-white">
            {data?.totalSessions}{" "}
            <span className="text-sm font-medium leading-6 text-gray-600">
              Séances
            </span>
          </p>
        </div>
      </div>

      <div className="h-[300px] w-full pb-0 pt-10">
        <BarChart
          chartData={[
            {
              name: "Difficulté",
              data: handleGetAverageDifficulty(),
            },
            {
              name: "Douleur",
              data: handleGetAveragePain(),
            },
          ]}
          chartOptions={barChartOptionsDailyTraffic(
            data?.sessions
              ? Object.entries(data?.sessions || {}).map(([key]) =>
                  formatKey(key)
                )
              : []
          )}
        />
      </div>
    </Card>
  );
};

export default DailyTraffic;
