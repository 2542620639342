import { useAppSelector } from "common/hooks";

export const useGetCurrentExercise = () => {
  const session = useAppSelector((state) => state.session.session);
  const completedExercises = useAppSelector(
    (state) => state.session.exerciseCompleted
  );

  if (!session) {
    return {
      currentExercise: null,
      numberOfExercisesLeft: 0,
      currentExerciseIndex: 0,
    };
  }

  const currentExerciseIndex = completedExercises.length;
  // Ensure session.exercises is an array and has elements before accessing
  const currentExercise = session.exercises[currentExerciseIndex];
  const numberOfExercisesLeft = session.exercises.length - currentExerciseIndex;

  return {
    currentExercise,
    numberOfExercisesLeft,
    currentExerciseIndex,
  };
};
