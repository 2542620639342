import { FunctionComponent } from "react";

interface SelectProps {
  options: string[];
  value: string | string[];
  onChange: (e: any) => void;
  label: string;
  multiple?: boolean;
}

export const Select: FunctionComponent<SelectProps> = ({
  label,
  onChange,
  options,
  value,
  multiple= false,
}) => {
  return (
    <div className="flex flex-col">
      <label
        className={`font-medium" ml-1.5 text-sm text-navy-700 dark:text-white`}
      >
        {label}
      </label>
      <select
        className="mt-2 rounded-xl border border-gray-300 bg-white/0 p-3 text-sm shadow-sm outline-none focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
        value={value}
        onChange={onChange}
        multiple={multiple}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};
