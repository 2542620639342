import { environment } from "common/environment";
import {
  Exercise,
  Pagination
} from "modules/exercises/types/exercises.interface";

export interface ExercisesBackend {
  getExercises(
    page: number,
    limit: number,
    searchName: string | undefined
  ): Promise<Pagination<Exercise>>;
}

let exercisesBackendInstance: ExercisesBackend | undefined;

export async function getExercisesBackend(): Promise<ExercisesBackend> {
  if (exercisesBackendInstance === undefined) {
    const mod = await import(`./${environment.CABINET_BACKEND}`);
    exercisesBackendInstance = new mod.default() as ExercisesBackend;
  }
  return exercisesBackendInstance;
}
