// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdAttachMoney,
  MdBarChart,
  MdChecklist,
  MdHome,
  MdLock,
  MdMedicalInformation,
  MdOutlineShoppingCart,
  MdPerson,
  MdSupervisorAccount,
  MdTabletMac
} from "react-icons/md";
import Patients from "views/admin/patients";
import CreatePatient from "views/admin/patients/create";
import UpdatePatient from "views/admin/patients/update";
import Protocols from "views/admin/protocol";
import CreateProtocol from "views/admin/protocol/create";
import UpdateProtocol from "views/admin/protocol/update";
import Staff from "views/admin/staff";
import AddToStaff from "views/admin/staff/create";
import Subscription from "views/admin/subscription";
import CancelSubscription from "views/admin/subscription/cancel";
import SubscriptionHistory from "views/admin/subscription/history";
import SuccessSubscription from "views/admin/subscription/success";
import Tablets from "views/admin/tablet";
import ForgetPassword from "views/auth/ForgetPassword";
import Session from "views/auth/Session";
import SetPassword from "views/auth/SetPassword";
import DuplicateProtocol from "./views/admin/protocol/duplicate";

const routes = [
  {
    name: "Tableau de bord",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "nft-marketplace",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Data Tables",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "data-tables",
    component: <DataTables />,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Watch Video",
    layout: "/auth",
    path: "session/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <Session />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Cabinet Staff",
    layout: "/admin",
    path: "staff",
    icon: <MdSupervisorAccount className="h-6 w-6" />,
    component: <Staff />,
    show: true,
    admin: true,
  },
  {
    name: "Protocole",
    layout: "/admin",
    path: "protocol",
    icon: <MdChecklist className="h-6 w-6" />,
    component: <Protocols />,
    show: true,
  },
  {
    name: "Patients",
    layout: "/admin",
    path: "patients",
    icon: <MdMedicalInformation className="h-6 w-6" />,
    component: <Patients />,
    show: true,
  },
  {
    name: "Create Patients",
    layout: "/admin",
    path: "patients/create",
    icon: <MdMedicalInformation className="h-6 w-6" />,
    component: <CreatePatient />,
  },
  {
    name: "Update Patients",
    layout: "/admin",
    path: "patients/:id",
    icon: <MdMedicalInformation className="h-6 w-6" />,
    component: <UpdatePatient />,
  },
  {
    name: "Abonnements",
    layout: "/admin",
    path: "subscriptions",
    icon: <MdAttachMoney className="h-6 w-6" />,
    component: <SubscriptionHistory />,
    show: true,
    admin: true,
  },
  {
    name: "Ajouter un membre au staff",
    layout: "/admin",
    path: "staff/create",
    icon: <MdHome className="h-6 w-6" />,
    component: <AddToStaff />,
    admin: true,
  },
  {
    name: "Définir un mot de passe",
    layout: "/auth",
    path: "set-password",
    icon: <MdHome className="h-6 w-6" />,
    component: <SetPassword />,
  },
  {
    name: "Mots de passe oublié?",
    layout: "/auth",
    path: "forgot-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ForgetPassword />,
  },
  {
    name: "Abonnement",
    layout: "/auth",
    path: "subscription",
    icon: <MdLock className="h-6 w-6" />,
    component: <Subscription />,
  },
  {
    name: "Abonnement",
    layout: "/auth",
    path: "subscription/success",
    icon: <MdLock className="h-6 w-6" />,
    component: <SuccessSubscription />,
  },
  {
    name: "Abonnement",
    layout: "/auth",
    path: "subscription/cancel",
    icon: <MdLock className="h-6 w-6" />,
    component: <CancelSubscription />,
  },
  {
    name: "Création de Protocole",
    layout: "/admin",
    path: "protocol/create",
    icon: <MdChecklist className="h-6 w-6" />,
    component: <CreateProtocol />,
  },
  {
    name: "Mis à jour de Protocole",
    layout: "/admin",
    path: "protocol/:id",
    icon: <MdChecklist className="h-6 w-6" />,
    component: <UpdateProtocol />,
  },
  {
    name: "Duplication de Protocole",
    layout: "/admin",
    path: "protocol/duplicate/:id",
    icon: <MdChecklist className="h-6 w-6" />,
    component: <DuplicateProtocol />,
  },
  {
    name: "Tablets",
    layout: "/admin",
    path: "tablet",
    icon: <MdTabletMac className="h-6 w-6" />,
    component: <Tablets />,
    show: true,
  },
];
export default routes;
