import { useAppDispatch, useAppSelector } from "common/hooks";
import { Spinner } from "components/Spinner";
import { Button } from "components/button";
import { useGetSession } from "modules/session/api";
import { resetSession } from "modules/session/api/store/slice";
import { useGetCurrentExercise } from "modules/session/api/utils/useGetCurrentExercise";
import { useParams } from "react-router-dom";
import ExerciseCard from "views/admin/profile/components/General";

export default function Session() {
  const id = useParams<{ id: string | undefined }>().id;
  useGetSession(id);

  const sessionInStore = useAppSelector((state) => state.session.session);
  const dispatch = useAppDispatch();
  const { currentExercise, numberOfExercisesLeft, currentExerciseIndex } =
    useGetCurrentExercise();

  const handleStopSession = async () => {
    await dispatch(resetSession());
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("sessionEnded");
    } else {
      console.log("ReactNativeWebView is not defined");
    }
  };

  if (!sessionInStore || numberOfExercisesLeft === 0) {
    return <Spinner />;
  }

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[620px]">
        <div className="flex justify-between">
          <p className="mb-6 ml-1 text-base text-gray-600">
            {sessionInStore?.name} -{" "}
            {numberOfExercisesLeft === 0
              ? "Dernier exercice"
              : `Exercice ${
                  currentExerciseIndex + 1
                } sur ${numberOfExercisesLeft} restants`}
          </p>
        </div>
        <ExerciseCard
          name={currentExercise.name}
          description={currentExercise.description}
          photoUrl={currentExercise.exercisePhotos}
          video={currentExercise.exerciseVideo}
          holdDuration={currentExercise.holdDuration}
          key={currentExercise._id}
          reps={currentExercise.reps}
          rest={currentExercise.rest}
          sets={currentExercise.sets}
          weight={currentExercise.weight}
          exerciseId={currentExercise._id}
        />
        <div className="m-auto mt-4 max-w-xs">
          <Button onClick={handleStopSession} variant="alert" size="xs">
            Arrêter la session
          </Button>
        </div>
      </div>
    </div>
  );
}
