import { useSnack } from "common/hooks";
import { useInfiniteQueryWithLoading } from "common/hooks/src/api/useInfiniteQueryWithLoading";
import { Pagination } from "modules/exercises/types/exercises.interface";
import {
  Tablet
} from "modules/tablet/types/tablet.interface";
import {
  UseInfiniteQueryResult
} from "react-query";
import { getTabletBackend } from "./backends";
import { TABLET_ROUTES } from "./tablet.api.enum";

export const useGetTablets = (
  searchName: string | undefined
): UseInfiniteQueryResult<Pagination<Tablet>> => {
  const setSnack = useSnack();

  const getExercises = async ({
    pageParam = 1,
  }): Promise<Pagination<Tablet>> => {
    const notificationsBackend = await getTabletBackend();
    return await notificationsBackend.getTablets(pageParam, 10, searchName);
  };

  return useInfiniteQueryWithLoading(
    TABLET_ROUTES.GET_ALL + searchName,
    getExercises,
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};
