import { Button } from "components/button";
import { Modal } from "components/modal";
import { FunctionComponent, useState } from "react";
import { useRateExerciseDifficulty } from "../api";

interface ExerciseCardProps {
  exerciseId: string;
  show: boolean;
}

export const EnterDifficultyModal: FunctionComponent<ExerciseCardProps> = ({
  exerciseId,
  show,
}) => {
  const [difficulty, setDifficulty] = useState<number | null>(null);
  const [painLevel, setPainLevel] = useState<number | null>(null);
  const { mutate } = useRateExerciseDifficulty(exerciseId);

  const handleContinue = () => {
    if (difficulty && painLevel) {
      mutate({ difficulty, pain: painLevel });
    }
  }

  if (!show) return null;

  return (
    <Modal>
      <div className="flex flex-col gap-8 px-10 pb-2 pt-10">
        <div>
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            Quelle était la difficulté de cet exercice?
          </h4>
          <div className="mt-4 flex gap-2">
            {Array.from({ length: 5 }).map((_, index) => {
              const currentDifficulty = index + 1;
              let bgColor = "bg-gray-200";
              let textColor = "text-gray-600";

              switch (currentDifficulty) {
                case 1:
                  bgColor = "bg-green-200";
                  break;
                case 2:
                  bgColor = "bg-blue-200";
                  break;
                case 3:
                  bgColor = "bg-yellow-200";
                  break;
                case 4:
                  bgColor = "bg-orange-200";
                  break;
                case 5:
                  bgColor = "bg-red-200";
                  break;
                default:
                  bgColor = "bg-gray-200";
              }

              // If the current difficulty is selected, change the background and text color
              if (difficulty === currentDifficulty) {
                bgColor = "bg-navy-700";
                textColor = "text-white";
              }

              return (
                <div
                  className={`h-10 w-10 cursor-pointer rounded-full ${bgColor}`}
                  onClick={() => setDifficulty(currentDifficulty)}
                >
                  <p
                    className={`flex h-full w-full items-center justify-center font-bold ${textColor}`}
                  >
                    {currentDifficulty}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="pb-2">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            Quelle degree de douleur avez-vous ressenti?
          </h4>
          <div className="mt-4 flex gap-2">
            {Array.from({ length: 5 }).map((_, index) => {
              const currentPainLevel = index + 1;
              let bgColor = "bg-gray-200";
              let textColor = "text-gray-600";

              switch (currentPainLevel) {
                case 1:
                  bgColor = "bg-green-200";
                  break;
                case 2:
                  bgColor = "bg-blue-200";
                  break;
                case 3:
                  bgColor = "bg-yellow-200";
                  break;
                case 4:
                  bgColor = "bg-orange-200";
                  break;
                case 5:
                  bgColor = "bg-red-200";
                  break;
                default:
                  bgColor = "bg-gray-200";
              }

              // If the current difficulty is selected, change the background and text color
              if (painLevel === currentPainLevel) {
                bgColor = "bg-navy-700";
                textColor = "text-white";
              }

              return (
                <div
                  className={`h-10 w-10 cursor-pointer rounded-full ${bgColor}`}
                  onClick={() => setPainLevel(currentPainLevel)}
                >
                  <p
                    className={`flex h-full w-full items-center justify-center font-bold ${textColor}`}
                  >
                    {currentPainLevel}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <Button variant="primary" size="md" onClick={() => handleContinue()}>
          Continuer
        </Button>
      </div>
    </Modal>
  );
};
