import { useSnack } from "common/hooks";
import { useInfiniteQueryWithLoading } from "common/hooks/src/api/useInfiniteQueryWithLoading";
import {
  Exercise,
  Pagination,
} from "modules/exercises/types/exercises.interface";
import { UseInfiniteQueryResult } from "react-query";
import { getExercisesBackend } from "./backends";
import { EXERCISE_ROUTES } from "./exercises.api.enum";

export const useGetExercises = ({
  searchName,
  limit = 10,
}: {
  searchName?: string | undefined;
  limit?: number;
}): UseInfiniteQueryResult<Pagination<Exercise>> => {
  const setSnack = useSnack();

  const getExercises = async ({
    pageParam = 1,
  }): Promise<Pagination<Exercise>> => {
    const notificationsBackend = await getExercisesBackend();
    return await notificationsBackend.getExercises(
      pageParam,
      limit,
      searchName
    );
  };

  return useInfiniteQueryWithLoading(
    EXERCISE_ROUTES.GET_ALL + searchName,
    getExercises,
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};
