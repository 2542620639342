import { useGetPatients } from "modules/patients/api";
import { Patient } from "modules/patients/types/patient.interface";
import { useState } from "react";
import PatientTable, { RowObjPatient } from "../default/components/patientTable";

const Patients = () => {
  const [searchName, setSearchName] = useState<string | undefined>(undefined);
  const { data, hasNextPage, fetchNextPage } = useGetPatients(searchName);
  const [currentPage, setCurrentPage] = useState(1);
  const flatData = data?.pages[currentPage - 1].data;

  const handleFormatData = (data: Patient[]): RowObjPatient[] => {
    return data.map((item) => ({
      id: item._id,
      name: item.firstName + " " + item.lastName,
      birthDay: item.birthDay,
      unicode: item.unicode,
    }));
  };

  const handleFetchNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFetchPrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="mt-5">
        {data?.pages.length > 0 && (
          <PatientTable
            tableData={handleFormatData(flatData)}
            searchName={searchName}
            setSearchName={setSearchName}
            currentPage={currentPage}
            totalPage={data.pages.length}
            hasNextPage={hasNextPage}
            handleFetchNextPage={handleFetchNextPage}
            handleFetchPrevPage={handleFetchPrevPage}
          />
        )}
      </div>
    </div>
  );
};

export default Patients;
