import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import styled, { keyframes } from "styled-components";

interface IProgressContextProps {
  toggleLoading: (value: boolean) => void;
  isLoading: boolean;
}

const ProgressContext = createContext<IProgressContextProps>(
  {} as IProgressContextProps
);

export const useProgress = () => useContext(ProgressContext);

export const ProgressProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoading = useCallback((value: boolean) => {
    setIsLoading(value);
  }, []);

  const progress = keyframes`
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
`;

  const Progress = styled.div`
    animation: ${progress} 1s infinite linear;
  `;

  const LeftRight = styled.div`
    transform-origin: 0% 50%;
  `;

  const FullWidth = styled.div`
    width: 100%;
  `;

  const FullHeight = styled.div`
    height: 5px;
    width: 100%;
    overflow: hidden;
  `;

  return (
    <ProgressContext.Provider value={{ toggleLoading, isLoading }}>
      {isLoading && (
        <div style={{ position: "fixed", top: 0, width: "100%", zIndex: 9999 }}>
          <FullWidth>
            <FullHeight className="bg-brand-100">
              <Progress as={LeftRight} className="h-full w-full bg-brand-500" />
            </FullHeight>
          </FullWidth>
        </div>
      )}
      {children}
    </ProgressContext.Provider>
  );
};
