import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import { queryClient } from "common/api";
import { ProgressProvider, SnackBarProvider } from "common/hooks";
import { persistor, store } from "common/store";
import { QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ProgressProvider>
        <SnackBarProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </SnackBarProvider>
      </ProgressProvider>
    </QueryClientProvider>
  </BrowserRouter>
);
