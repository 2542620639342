import { useGetProtocols } from "modules/protocol/api";
import { Protocol } from "modules/protocol/types/protocol.interface";
import { useState } from "react";
import ProtocolTable, {
  RowObjProtocol,
} from "../default/components/protocolTable";

const Protocols = () => {
  const [searchName, setSearchName] = useState<string | undefined>(undefined);
  const { data, hasNextPage, fetchNextPage } = useGetProtocols(searchName);
  const [currentPage, setCurrentPage] = useState(1);
  const flatData =
    data?.pages[currentPage - 1] && data?.pages[currentPage - 1].data;

  const handleFormatData = (data: Protocol[]): RowObjProtocol[] => {
    if (!flatData) return [];

    return data.map((item) => ({
      id: item._id,
      name: item.name,
      numberOfExercise: item.exercises.length,
      template: !item.cabinetId && item.template,
    }));
  };

  const handleFetchNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFetchPrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="mt-5">
        {flatData && (
          <ProtocolTable
            tableData={handleFormatData(flatData)}
            searchName={searchName}
            setSearchName={setSearchName}
            currentPage={currentPage}
            totalPage={data.pages.length}
            hasNextPage={hasNextPage}
            handleFetchNextPage={handleFetchNextPage}
            handleFetchPrevPage={handleFetchPrevPage}
          />
        )}
      </div>
    </div>
  );
};

export default Protocols;
