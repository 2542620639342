import { SnackBar } from "components/snackbar";
import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

export type SeverityType = "success" | "error" | "info" | "warning";

export interface IUseSnackProps {
  title: string;
  severityType: SeverityType;
}

type SnackBarContextActions = {
  setSnackBar: ({ title, severityType }: IUseSnackProps) => void;
};

const SnackBarContext = createContext({} as SnackBarContextActions);

export const SnackBarProvider: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const [formValues, setFormValues] = useState<IUseSnackProps>({
    title: "",
    severityType: "success",
  });

  const setSnackBar = useCallback(
    ({ title, severityType }: IUseSnackProps): void => {
      setOpen(true);
      setFormValues({ ...formValues, severityType, title });
    },
    [formValues, setFormValues]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setOpen(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [open]);

  return (
    <SnackBarContext.Provider value={{ setSnackBar }}>
      {open && (
        <SnackBar
          handleClose={handleClose}
          title={formValues.title}
          severityType={formValues.severityType}
        />
      )}
      {children}
    </SnackBarContext.Provider>
  );
};

export const useSnack = (): (({
  title,
  severityType,
}: IUseSnackProps) => void) => {
  const context = useContext(SnackBarContext);

  if (!context) {
    throw new Error("useSnack must be used within a SnackBarProvider");
  }

  return context.setSnackBar;
};
