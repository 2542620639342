import Card from "components/card";
import { useGetExercises } from "modules/exercises/api";
import { Exercise } from "modules/exercises/types/exercises.interface";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import {
  RiCheckboxBlankCircleFill,
  RiCheckboxCircleFill,
} from "react-icons/ri";
import { CreateProtocolExercisePayload } from "../types/protocol.interface";
import { MdAddCircle } from "react-icons/md";

interface SelectExerciseProps {
  exercises: CreateProtocolExercisePayload[];
  setExercises: (value: CreateProtocolExercisePayload[]) => void;
}

export const SelectExercise: FunctionComponent<SelectExerciseProps> = ({
  exercises,
  setExercises,
}) => {
  const [searchName, setSearchName] = useState<string | undefined>(undefined);
  const { data } = useGetExercises({ searchName, limit: 500 });
  const flatData = data?.pages[0].data;
  const [isInputFocused, setIsInputFocused] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleFormatData = (data: Exercise[]) => {
    if (!data) return;

    return data.map((item) => ({
      id: item._id,
      name: item.name,
      image: item.photoUrls[0],
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsInputFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div ref={ref}>
      <div className="mt-5">
        <div className="flex items-center gap-4">
          <div className="flex h-[45px] items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[250px]">
            <p className="pl-3 pr-2 text-xl">
              <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
            </p>
            <input
              type="text"
              placeholder="Ajouter un exercice"
              className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              onFocus={() => setIsInputFocused(true)}
            />
          </div>
          <div className="flex items-center justify-center">
            <MdAddCircle
              size={"1.75rem"}
              color={"#4318FF"}
              onClick={() => setIsInputFocused(true)}
              className={"cursor-pointer"}
            />
          </div>
        </div>{" "}
        ·
        {isInputFocused && (
          <div className="mt-5 grid grid-cols-2 grid-rows-1 gap-4 md:grid-cols-4 lg:grid-cols-8">
            {flatData?.length > 0 &&
              handleFormatData(flatData).map((exercise) => (
                <Card
                  extra={`flex flex-col w-full h-full bg-white cursor-pointer`}
                >
                  <div className="h-full w-full">
                    <div className="relative w-full">
                      <img
                        src={exercise.image}
                        className="h-full w-full rounded-md"
                        alt=""
                      />
                    </div>
                    {exercises?.find((ex) => ex.exerciseId === exercise.id) ? (
                      <RiCheckboxCircleFill
                        className="absolute right-2 top-2 text-2xl text-green-500"
                        onClick={() =>
                          setExercises(
                            exercises.filter(
                              (ex) => ex.exerciseId !== exercise.id
                            )
                          )
                        }
                      />
                    ) : (
                      <RiCheckboxBlankCircleFill
                        className="absolute right-2 top-2 text-2xl text-gray-400"
                        onClick={() =>
                          setExercises([
                            ...exercises,
                            {
                              exerciseId: exercise.id,
                              exerciseName: exercise.name,
                              sets: 0,
                              reps: 0,
                              rest: 0,
                              holdDuration: 0,
                              weight: 0,
                            },
                          ])
                        }
                      />
                    )}

                    <p className="text-center text-sm font-bold text-navy-700 dark:text-white">
                      {exercise.name}
                    </p>
                  </div>
                </Card>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};
