import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    AuthenticatedUser,
    UserTokens,
} from "modules/auth/types/auth.interface";
import initialState from "./state";

interface ISetUserPayload {
  user: AuthenticatedUser;
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<ISetUserPayload>) {
      state.user = action.payload.user;
    },
    setUserToken(state, action: PayloadAction<UserTokens>) {
      if (!state.user) {
        throw new Error("User is not set, cannot set token.");
      }

      state.user.token = action.payload;
    },
    resetUser(state) {
      state.user = null;
    },
  },
});

export const { setUser, resetUser, setUserToken } = userSlice.actions;
