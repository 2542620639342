import { Session } from "modules/session/types/session.interface";

interface SessionState {
  session: Session | null;
  exerciseCompleted: string[];
}

const initialState: SessionState = {
  session: null,
  exerciseCompleted: [],
};

export default initialState;
