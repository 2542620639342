import { FunctionComponent, PropsWithChildren } from "react";

interface Props {
  title?: string;
}

export const CardWithTitle: FunctionComponent<PropsWithChildren<Props>> = ({
  title,
  children,
}) => {
  return (
    <div className="mt-5 rounded-xl bg-white p-5 shadow-lg">
      {title && (
        <h4 className="mb-2.5 text-3xl font-bold text-navy-700 dark:text-white">
          {title}
        </h4>
      )}
      {children}
    </div>
  );
};
