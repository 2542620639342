import { useCustomForm } from "common/hooks/src/api/useCustomForm";
import { Button } from "components/button";
import { CardWithTitle } from "components/card/CardWithTitle";
import InputField from "components/fields/InputField";
import { useCreateProtocol, useGetProtocol } from "modules/protocol/api";
import { SelectExercise } from "modules/protocol/components/selectExercise";
import { CreateProtocolExercisePayload } from "modules/protocol/types/protocol.interface";
import { FormEvent, useEffect, useState } from "react";
import { MdArrowDownward, MdArrowUpward, MdRemoveCircle } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useGetExercises } from "../../../../modules/exercises/api";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const DuplicateProtocol = () => {
  const id = useParams<{ id: string | undefined }>().id;
  const { data } = useGetProtocol(id);
  const { mutate } = useCreateProtocol();
  const [name, setName] = useState("");
  const { formValues, setFormValues } = useCustomForm<
    CreateProtocolExercisePayload[]
  >([]);
  const [template, setTemplate] = useState(false);
  const { data: exercises } = useGetExercises({
    limit: 500,
  });

  const isSubmitDisabled = !name || formValues.length === 0;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    mutate({
      name,
      exercises: formValues,
      template,
    });
  };

  const getExerciseImage = (exercise: CreateProtocolExercisePayload) => {
    if (!exercises) return;

    const exerciseData = exercises.pages
      .map((page) => page.data)
      .flat()
      .find((ex) => ex._id === exercise.exerciseId);

    if (!exerciseData) return;

    return (
      <Zoom>
        <img
          src={exerciseData.photoUrls[0]}
          alt="exercise"
          className="h-20 w-20 rounded-full object-cover"
        />
      </Zoom>
    );
  };

  const handleRenderExercises = () => {
    if (!formValues) return;

    return formValues.map((exercice, index) => (
      <div key={index} className="mt-10 flex w-full flex-col  gap-5">
        <div className="flex items-center gap-2">
          {getExerciseImage(exercice)}
          <p className="text-lg font-semibold text-navy-700 dark:text-white">
            <span>{index + 1} - </span>
            {exercice.exerciseName}
          </p>
          <div className="flex items-center justify-start">
            <MdRemoveCircle
              className="cursor-pointer text-xl text-red-500"
              onClick={() =>
                setFormValues([
                  ...formValues.slice(0, index),
                  ...formValues.slice(index + 1),
                ])
              }
            />
          </div>
        </div>
        <div className={"flex items-center gap-2"}>
          <div className="grid w-full grid-cols-2 gap-5 sm:grid-cols-3 lg:grid-cols-5">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Répétitions"
              placeholder="0"
              id="reps"
              type="number"
              value={formValues[index].reps}
              onChange={(e) =>
                setFormValues([
                  ...formValues.slice(0, index),
                  { ...formValues[index], reps: parseInt(e.target.value) },
                  ...formValues.slice(index + 1),
                ])
              }
            />
            <InputField
              variant="auth"
              extra="mb-3"
              label="Série"
              placeholder="0"
              id="sets"
              type="number"
              value={formValues[index].sets}
              onChange={(e) =>
                setFormValues([
                  ...formValues.slice(0, index),
                  { ...formValues[index], sets: parseInt(e.target.value) },
                  ...formValues.slice(index + 1),
                ])
              }
            />
            <InputField
              variant="auth"
              extra="mb-3"
              label="Repos"
              placeholder="0"
              id="rest"
              type="number"
              value={formValues[index].rest}
              onChange={(e) =>
                setFormValues([
                  ...formValues.slice(0, index),
                  { ...formValues[index], rest: parseInt(e.target.value) },
                  ...formValues.slice(index + 1),
                ])
              }
            />
            <InputField
              variant="auth"
              extra="mb-3"
              label="Poids"
              placeholder="0"
              id="weight"
              type="number"
              value={formValues[index].weight}
              onChange={(e) =>
                setFormValues([
                  ...formValues.slice(0, index),
                  { ...formValues[index], weight: parseInt(e.target.value) },
                  ...formValues.slice(index + 1),
                ])
              }
            />
            <InputField
              variant="auth"
              extra="mb-3"
              label="Durée de maintien"
              placeholder="0"
              id="holdDuration"
              type="number"
              value={formValues[index].holdDuration}
              onChange={(e) =>
                setFormValues([
                  ...formValues.slice(0, index),
                  {
                    ...formValues[index],
                    holdDuration: parseInt(e.target.value),
                  },
                  ...formValues.slice(index + 1),
                ])
              }
            />
          </div>
          <div className={`flex items-center gap-4`}>
            <MdArrowUpward
              className="cursor-pointer text-xl text-navy-700 dark:text-white"
              onClick={() => {
                if (index === 0) return;
                setFormValues([
                  ...formValues.slice(0, index - 1),
                  formValues[index],
                  formValues[index - 1],
                  ...formValues.slice(index + 1),
                ]);
              }}
            />

            <MdArrowDownward
              className="cursor-pointer text-xl text-navy-700 dark:text-white"
              onClick={() => {
                if (index === formValues.length - 1) return;
                setFormValues([
                  ...formValues.slice(0, index),
                  formValues[index + 1],
                  formValues[index],
                  ...formValues.slice(index + 2),
                ]);
              }}
            />
          </div>
        </div>
      </div>
    ));
  };

  useEffect(() => {
    if (data) {
      setName(data.name);
      setTemplate(data.template);
      setFormValues(
        data.exercises as unknown as CreateProtocolExercisePayload[]
      );
    }
  }, [data, setFormValues]);

  return (
    <div className="flex flex-col gap-4">
      <CardWithTitle title="Informations du protocol">
        <div className="grid grid-cols-2 gap-5">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Nom du protocol*"
            placeholder="Protocol 1"
            id="nom-du-protocol"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </CardWithTitle>
      <CardWithTitle title="Exercises du protocol">
        <SelectExercise exercises={formValues} setExercises={setFormValues} />
        {handleRenderExercises()}
      </CardWithTitle>
      <div className="mt-2 flex w-full justify-end">
        <div className="w-full max-w-xs">
          <Button onClick={handleSubmit} disabled={isSubmitDisabled}>
            Créer le protocol
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DuplicateProtocol;
