import { AuthenticatedUser } from "modules/auth/types/auth.interface";

interface IUserState {
  user: AuthenticatedUser | null;
}

const initialState: IUserState = {
  user: null,
};

export default initialState;
