import { environment } from "common/environment";
import { Pagination } from "modules/exercises/types/exercises.interface";
import {
  Tablet
} from "modules/tablet/types/tablet.interface";

export interface ITabletBackend {
  getTablets(
    page: number,
    limit: number,
    searchName: string | undefined
  ): Promise<Pagination<Tablet>>;
}

let tabletBackendInstance: ITabletBackend | undefined;

export async function getTabletBackend(): Promise<ITabletBackend> {
  if (tabletBackendInstance === undefined) {
    const mod = await import(`./${environment.CABINET_BACKEND}`);
    tabletBackendInstance = new mod.default() as ITabletBackend;
  }
  return tabletBackendInstance;
}
