import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Session } from "modules/session/types/session.interface";
import initialState from "./state";

interface ISetSessionPayload {
  session: Session;
}

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSession(state, action: PayloadAction<ISetSessionPayload>) {
      state.session = action.payload.session;
    },
    setAddExerciseCompleted(state, action: PayloadAction<string>) {
      state.exerciseCompleted.push(action.payload);
    },
    resetSession(state) {
      state.session = null;
      state.exerciseCompleted = [];
    },
  },
});

export const { setSession, resetSession, setAddExerciseCompleted } =
  sessionSlice.actions;
