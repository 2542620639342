import { IUseSnackProps, SeverityType } from "common/hooks";
import { FunctionComponent } from "react";

const SnackbarIcon: FunctionComponent<{
  severityType: IUseSnackProps["severityType"];
}> = ({ severityType }) => {
  const iconMap = {
    success: (
      <svg
        className="h-6 w-6 text-green-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        data-testid="success-icon"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 13l4 4L19 7"
        />
      </svg>
    ),
    error: (
      <svg
        className="h-6 w-6 text-red-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        data-testid="error-icon"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    ),
    info: (
      <svg
        className="h-6 w-6 text-blue-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        data-testid="info-icon"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 6v2m0 10V10m0 0h.01m-.01 10c-1.11 0-2-.89-2-2 0-1.11.89-2 2-2 1.11 0 2 .89 2 2 0 1.11-.89 2-2 2zM3 12v6a9 9 0 0 0 18 0v-6"
        />
      </svg>
    ),
    warning: (
      <svg
        className="h-6 w-6 text-yellow-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        data-testid="warning-icon"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 2c4.411 0 8 3.589 8 8s-3.589 8-8 8-8-3.589-8-8 3.589-8 8-8zm0 3v5m0 4v.01"
        />
      </svg>
    ),
  };

  return iconMap[severityType] || null;
};

interface SnackBarProps {
  handleClose: () => void;
  title: string;
  severityType: SeverityType;
}

export const SnackBar: FunctionComponent<SnackBarProps> = ({
  handleClose,
  severityType,
  title,
}) => {
  return (
    <div className="fixed top-6 right-4 z-50">
      <div className="rounded-md bg-white p-4 shadow-md">
        <div className="flex items-center gap-5">
          <div className="flex items-center">
            <div className="mr-2">
              <SnackbarIcon severityType={severityType} />
            </div>
            <div className="text-sm font-medium text-gray-800">{title}</div>
          </div>

          <button
            className="mt-1 text-gray-500 hover:text-gray-600"
            onClick={handleClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
