import { Button } from "components/button";
import Card from "components/card";
import { EnterDifficultyModal } from "modules/session/components/enterDifficultyModal";
import { FunctionComponent, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

interface ExerciseCardProps {
  name: string;
  description: string;
  photoUrl: string[];
  video?: string;
  reps?: number;
  sets?: number;
  rest?: number;
  weight?: number;
  holdDuration?: number;
  exerciseId: string;
}

const ExerciseCard: FunctionComponent<ExerciseCardProps> = ({
  description,
  name,
  photoUrl,
  holdDuration,
  reps,
  rest,
  sets,
  weight,
  video,
  exerciseId,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleRenderPhotos = () => {
    if (video) return;

    return (
      <div className="flex w-full flex-col items-center justify-center gap-1 rounded-2xl bg-gray-200 sm:h-48 sm:flex-row mb-4">
        <div style={{ width: "100%", height: "100%" }}>
          <Zoom>
            <img
              src={photoUrl[0]}
              alt="exercise"
              className="h-full w-full object-cover"
            />
          </Zoom>
        </div>
        <div style={{ width: "100%", height: "100%" }}>
          <Zoom>
            <img
              src={photoUrl[1]}
              alt="exercise"
              className="h-full w-full object-cover"
            />
          </Zoom>
        </div>
      </div>
    );
  };

  const handleRenderVideo = () => {
    if (!video) return;

    return (
      <div className="flex w-full flex-col items-center justify-center gap-1 rounded-2xl bg-gray-200 sm:h-48 sm:flex-row">
        <video
          className="h-full w-full object-cover"
          controls
          autoPlay
          src={video}
        ></video>
      </div>
    );
  };

  return (
    <Card extra={"w-full h-full p-3"}>
      {handleRenderVideo()}
      {handleRenderPhotos()}
      <div className="mb-8 mt-5 w-full">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          {name}
        </h4>
        <p className="mt-2 px-2 text-base text-gray-600">{description}</p>
      </div>
      <div className="grid grid-cols-2 gap-4 px-2">
        {sets && (
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-lg dark:!bg-navy-700 dark:shadow-none">
            <p className="text-base text-gray-800">Série</p>
            <p className="text-lg font-medium text-navy-900 dark:text-gray-100">
              {sets}
            </p>
          </div>
        )}

        {reps && (
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-lg dark:!bg-navy-700 dark:shadow-none">
            <p className="text-base text-gray-800">Répétition</p>
            <p className="text-lg font-medium text-navy-900 dark:text-gray-100">
              {reps}
            </p>
          </div>
        )}

        {rest && (
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-lg dark:!bg-navy-700 dark:shadow-none">
            <p className="text-base text-gray-800">Repos</p>
            <p className="text-lg font-medium text-navy-900 dark:text-gray-100">
              {rest}
            </p>
          </div>
        )}

        {weight && (
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-lg dark:!bg-navy-700 dark:shadow-none">
            <p className="text-base text-gray-800">Poids</p>
            <p className="text-lg font-medium text-navy-900 dark:text-gray-100">
              {weight}
            </p>
          </div>
        )}

        {holdDuration && (
          <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-lg dark:!bg-navy-700 dark:shadow-none">
            <p className="text-base text-gray-800">
              Durée de maintien <span className="text-xs">(en secondes)</span>
            </p>
            <p className="text-lg font-medium text-navy-900 dark:text-gray-100">
              {holdDuration}
            </p>
          </div>
        )}
      </div>
      <EnterDifficultyModal exerciseId={exerciseId} show={showModal} />
      <Button onClick={() => setShowModal(true)}>Continuer</Button>
    </Card>
  );
};

export default ExerciseCard;
