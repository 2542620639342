import { useAppSelector } from "common/hooks";
import { useGetSubscriptionStatus } from "modules/cabinets/api";
import { FunctionComponent, PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";

export const LayoutController: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const auth = useAppSelector((state) => state.user.user) !== null;
  const { data, isLoading } = useGetSubscriptionStatus();
  const location = useLocation();

  if (
    !isLoading &&
    !data?.data.active &&
    auth &&
    !location.pathname.includes("/auth/subscription")
  ) {
    return <Navigate to="/auth/subscription" replace={true} />;
  }

  if (
    !isLoading &&
    data?.data.active &&
    auth &&
    location.pathname.startsWith("/auth")
  ) {
    return <Navigate to="/" replace={true} />;
  }

  if (!auth && !location.pathname.startsWith("/auth")) {
    return <Navigate to="/auth/sign-in" replace={true} />;
  }

  return <>{children}</>;
};
