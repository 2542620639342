import { useCustomForm } from "common/hooks/src/api/useCustomForm";
import { Button } from "components/button";
import { CardWithTitle } from "components/card/CardWithTitle";
import InputField from "components/fields/InputField";
import { Select } from "components/select";
import { useGetCabinetStaff } from "modules/cabinets/api";
import {
  useArchivePatient,
  useGetPatient,
  useUpdatePatient,
} from "modules/patients/api";
import {
  CreatePatientPayload,
  GENDER,
} from "modules/patients/types/patient.interface";
import { useGetProtocols } from "modules/protocol/api";
import { FormEvent, useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import DailyTraffic from "views/admin/default/components/DailyTraffic";

const UpdatePatient = () => {
  const id = useParams<{ id: string | undefined }>().id;
  const { data: patient } = useGetPatient(id);
  const { mutate } = useUpdatePatient(id);
  const { mutate: archivePatient } = useArchivePatient();
  const { data: protocols } = useGetProtocols(undefined, 100);
  const { data: practitioners } = useGetCabinetStaff();
  const { formValues, setFormValues } = useCustomForm<CreatePatientPayload>({
    birthDay: new Date(),
    cabinetId: "",
    firstName: "",
    gender: GENDER.MALE,
    lastName: "",
    practitionerId: "",
    activeProtocolIds: [],
  });

  const isSubmitDisabled =
    !formValues.firstName ||
    !formValues.lastName ||
    !formValues.birthDay ||
    formValues.activeProtocolIds.length < 0 ||
    !formValues.gender ||
    !formValues.practitionerId;

  const retrieveProtocolId = useCallback(
    (protocolName: string) => {
      const protocol = protocols?.pages[0].data.find(
        (protocol) => protocol.name === protocolName
      );
      return protocol?._id;
    },
    [protocols?.pages]
  );

  const retrieveProtocolNames = (protocolIds: string[]): string[] => {
    const names: string[] = protocolIds.reduce((acc: string[], protocolId) => {
      const protocol = protocols?.pages[0].data.find(
        (protocol) => protocol._id === protocolId
      );
      if (protocol?.name) acc.push(protocol.name);
      return acc;
    }, []);

    return names;
  };

  const retrievePractitionerId = (practitionerName: string) => {
    const practitioner = practitioners?.find(
      (practitioner) => practitioner.name === practitionerName
    );
    return practitioner?.id;
  };

  const retrievePractitionerName = (practitionerId: string) => {
    const practitioner = practitioners?.find(
      (practitioner) => practitioner.id === practitionerId
    );
    return practitioner?.name;
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    mutate({
      ...formValues,
    });
  };

  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (patient && firstRender) {
      setFormValues({
        firstName: patient.firstName,
        lastName: patient.lastName,
        birthDay: new Date(patient.birthDay),
        cabinetId: patient.cabinetId,
        gender: patient.gender,
        practitionerId: patient.practitionerId,
        activeProtocolIds: patient.activeProtocolIds,
      });
      setFirstRender(false);
    }
  }, [firstRender, formValues, patient, setFormValues]);

  useEffect(() => {
    if (practitioners?.length && !formValues.practitionerId) {
      setFormValues({
        ...formValues,
        practitionerId: practitioners[0].id,
      });
    }
  }, [formValues, practitioners, setFormValues]);

  const handleGetDate = () => {
    if (!formValues.birthDay) return new Date();

    return new Date(formValues.birthDay);
  };

  const handleChangeProtocols = useCallback(
    (e: any) => {
      const protocolName = e.target.value;
      const protocolId = retrieveProtocolId(protocolName);
      if (formValues.activeProtocolIds.includes(protocolId)) {
        setFormValues({
          ...formValues,
          activeProtocolIds: formValues.activeProtocolIds.filter(
            (id) => id !== protocolId
          ),
        });
      } else {
        setFormValues({
          ...formValues,
          activeProtocolIds: [...formValues.activeProtocolIds, protocolId],
        });
      }
    },
    [formValues, retrieveProtocolId, setFormValues]
  );

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <CardWithTitle title="Information du patient">
        <div className="grid grid-cols-2 gap-5">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Prénom*"
            placeholder="Paul"
            id="firstName"
            type="text"
            value={formValues.firstName}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                firstName: e.target.value,
              })
            }
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Nom*"
            placeholder="Dupont"
            id="lastName"
            type="text"
            value={formValues.lastName}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                lastName: e.target.value,
              })
            }
          />
          <Select
            options={["Homme", "Femme"]}
            value={formValues.gender === GENDER.MALE ? "Homme" : "Femme"}
            onChange={(e) =>
              e.target.value === "Homme"
                ? setFormValues({
                    ...formValues,
                    gender: GENDER.MALE,
                  })
                : setFormValues({
                    ...formValues,
                    gender: GENDER.FEMALE,
                  })
            }
            label={"Sexe*"}
          />
          <div className="flex flex-col gap-2">
            <label
              className={`font-medium" ml-1.5 text-sm text-navy-700 dark:text-white`}
            >
              Date de naissance*
            </label>
            <DatePicker
              selected={handleGetDate()}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => {
                setFormValues({
                  ...formValues,
                  birthDay: date,
                });
              }}
              className="w-full rounded-xl border border-gray-300 bg-white/0 p-3 text-sm shadow-sm outline-none focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
            />
          </div>
        </div>
      </CardWithTitle>
      <CardWithTitle title="Choix des protocols">
        <Select
          options={
            protocols?.pages[0].data.map((protocol) => protocol.name) || []
          }
          value={retrieveProtocolNames(formValues.activeProtocolIds)}
          onChange={(e) => handleChangeProtocols(e)}
          multiple={true}
          label={"Protocol*"}
        />
      </CardWithTitle>
      <CardWithTitle title="Choix du praticien">
        <Select
          options={
            practitioners?.map((practitioner) => practitioner.name) || []
          }
          value={retrievePractitionerName(formValues.practitionerId)}
          onChange={(e) =>
            setFormValues({
              ...formValues,
              practitionerId: retrievePractitionerId(e.target.value),
            })
          }
          label={"Praticien*"}
        />
      </CardWithTitle>
      <div className="w-full">
        <DailyTraffic id={id} />
      </div>
      <div className="mt-2 flex w-full justify-between">
        <div className="w-full max-w-xs">
          <Button onClick={() => archivePatient(id)} variant="alert">
            Archiver le patient
          </Button>
        </div>
        <div className="w-full max-w-xs">
          <Button onClick={handleSubmit} disabled={isSubmitDisabled}>
            Mêttre à jour le patient
          </Button>
        </div>
      </div>
    </form>
  );
};

export default UpdatePatient;
