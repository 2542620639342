import { SuccessResponse } from "common/common.interface";
import { environment } from "common/environment";
import {
  RateExercisePayload,
  Session,
  SessionChart,
} from "modules/session/types/session.interface";

export interface SessionBackend {
  startSession: (cabinetId: string, code: string) => Promise<Session>;
  getSession: (sessionId: string) => Promise<Session>;
  rateExercise: (
    exerciseId: string,
    sessionId: string,
    payload: RateExercisePayload
  ) => Promise<SuccessResponse>;
  endSession: (sessionId: string) => Promise<SuccessResponse>;
  getUserSessionChart: (userId: string) => Promise<SessionChart>;
}

let sessionBackendInstance: SessionBackend | undefined;

export async function getSessionBackend(): Promise<SessionBackend> {
  if (sessionBackendInstance === undefined) {
    const mod = await import(`./${environment.SESSION_BACKEND}`);
    sessionBackendInstance = new mod.default() as SessionBackend;
  }
  return sessionBackendInstance;
}
