/* eslint-disable */
import { useEffect } from "react";
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from "react-query";
import { useProgress } from "../useProgress";

export const useInfiniteQueryWithLoading = <TResult, TError>(
  queryKey: string | string[],
  queryFn: (context: { pageParam?: number }) => Promise<TResult>, // Remove the default value from here
  options: Omit<
    UseInfiniteQueryOptions<
      TResult,
      TError,
      TResult,
      TResult,
      readonly unknown[]
    >,
    "queryKey" | "queryFn"
  >
): UseInfiniteQueryResult<TResult, TError> => {
  const { toggleLoading } = useProgress();

  const queryResult = useInfiniteQuery(
    [queryKey] as readonly unknown[],
    queryFn,
    {
      ...options,
      onSettled: () => {
        toggleLoading(false);
      },
      getPreviousPageParam: (firstPage: any, pages) => {
        if (firstPage.count > pages.length) {
          return pages.length - 1;
        }
        return undefined;
      },
      getNextPageParam: (lastPage: any, pages) => {
        if (lastPage.count > pages.length) {
          return pages.length + 1;
        }
        return undefined;
      },
    }
  );

  const isLoading = queryResult.isLoading;

  useEffect(() => {
    if (isLoading !== undefined) {
      toggleLoading(isLoading);
    }
  }, [isLoading, toggleLoading]);

  return queryResult;
};
