import { SuccessResponse } from "common/common.interface";
import { useAppSelector, useSnack } from "common/hooks";
import { useMutationWithLoading } from "common/hooks/src/api/useMutationWithLoading";
import { useQueryWithLoading } from "common/hooks/src/api/useQueryWithLoading";
import { CabinetUserRole } from "modules/auth/types/auth.interface";
import {
  AddStaffPayload,
  CabinetStaff,
  PaymentHistory,
} from "modules/cabinets/types/cabinets.interface";
import { UseMutationResult, UseQueryResult, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { getCabinetBackend } from "./backends";
import { CABINETS_ROUTES } from "./cabinets.api.enum";

export const useGetCabinetStaff = (): UseQueryResult<CabinetStaff[]> => {
  const setSnack = useSnack();

  const getCabinetStaff = async (): Promise<CabinetStaff[]> => {
    const cabinetBackend = await getCabinetBackend();
    return await cabinetBackend.getCabinetStaff();
  };

  return useQueryWithLoading(
    CABINETS_ROUTES.GET_CABINET_STAFF,
    () => getCabinetStaff(),
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useGetCabinetSubHistory = (): UseQueryResult<PaymentHistory> => {
  const setSnack = useSnack();

  const getCabinetSubHistory = async (): Promise<PaymentHistory> => {
    const cabinetBackend = await getCabinetBackend();
    return await cabinetBackend.getCabinetSubHistory();
  };

  return useQueryWithLoading(
    CABINETS_ROUTES.GET_CABINET_SUB_HISTORY,
    () => getCabinetSubHistory(),
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useGetSubscriptionStatus = (): UseQueryResult<
  SuccessResponse<{
    active: true;
  }>
> => {
  const setSnack = useSnack();
  const user = useAppSelector((state) => state.user.user);

  const getSubscriptionStatus = async (): Promise<
    SuccessResponse<{ active: true }>
  > => {
    const cabinetBackend = await getCabinetBackend();
    return await cabinetBackend.getSubscriptionStatus();
  };

  return useQueryWithLoading(
    CABINETS_ROUTES.GET_SUBSCRIPTION_STATUS,
    () => getSubscriptionStatus(),
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
      enabled: user !== null,
    }
  );
};

export const useAddToStaff = (): UseMutationResult<
  CabinetStaff,
  Error,
  AddStaffPayload
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const addToStaff = async (
    payload: AddStaffPayload
  ): Promise<CabinetStaff> => {
    const cabinetBackend = await getCabinetBackend();
    return await cabinetBackend.addToStaff(payload);
  };

  return useMutationWithLoading(
    (payload: AddStaffPayload) => addToStaff(payload),
    {
      onSuccess() {
        setSnack({
          title: "Membre du staff ajouter avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(CABINETS_ROUTES.GET_CABINET_STAFF);

        navigate("/admin/staff");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useRemoveFromStaff = (): UseMutationResult<
  SuccessResponse,
  Error,
  string
> => {
  const setSnack = useSnack();
  const queryClient = useQueryClient();

  const removeStaff = async (userId: string): Promise<SuccessResponse> => {
    const cabinetBackend = await getCabinetBackend();
    return await cabinetBackend.removeFromStaff(userId);
  };

  return useMutationWithLoading((userId: string) => removeStaff(userId), {
    onSuccess() {
      setSnack({
        title: "Membre du staff supprimer avec succès!",
        severityType: "success",
      });

      queryClient.refetchQueries(CABINETS_ROUTES.GET_CABINET_STAFF);
    },
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
  });
};

export const useGetSubscriptionLink = (): UseQueryResult<
  SuccessResponse<{
    link: string;
  }>
> => {
  const setSnack = useSnack();
  const user = useAppSelector((state) => state.user.user);

  const getSubscriptionLink = async (): Promise<
    SuccessResponse<{
      link: string;
    }>
  > => {
    const cabinetBackend = await getCabinetBackend();
    return await cabinetBackend.getSubscriptionLink();
  };

  return useQueryWithLoading(
    CABINETS_ROUTES.GET_SUBSCRIPTION_LINK,
    () => getSubscriptionLink(),
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
      enabled: user !== null && user.role === CabinetUserRole.Admin,
    }
  );
};
